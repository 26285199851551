import { useRouter } from "next/router"
import { BlitzPage } from "@blitzjs/next"
import React, { useEffect } from "react"
import { showErrorModal } from "@Utils/errors"
import { LoginForm } from "app/core/components/Auth/LoginForm"
import SignOnLayout from "app/core/layouts/SignOnLayout"
import { validateAccess } from "app/auth/helpers/authorize"
import { Role } from "db"
import { AccessScope } from "app/auth/types"
import { gSSP } from "app/blitz-server"
import { redirectTo } from "app/blitz-server-utils"

export const getServerSideProps = gSSP(async ({ ctx }) => {
  const authorized = validateAccess({
    ctx,
    args: [{ allowedRoles: [Role.CUSTOMER], accessScope: AccessScope.Full }],
  })

  if (authorized) {
    return redirectTo("/main")
  }

  return { props: {} }
})

const LoginPage: BlitzPage = () => {
  const { authError } = useRouter().query
  const router = useRouter()

  useEffect(() => {
    if (authError && typeof authError === "string") {
      showErrorModal(authError)
      router.replace("/login")
    }
  }, [authError, router])

  return <LoginForm />
}

LoginPage.getLayout = (page) => <SignOnLayout title="Log In">{page}</SignOnLayout>

export default LoginPage
