import { useRouter } from "next/router"
import { useMutation } from "@blitzjs/rpc"
import { zodResolver } from "@hookform/resolvers/zod"
import { Modal, message } from "antd"
import { CustomerStatus } from "db"
import { useCallback, useState } from "react"
import { FormProvider, useForm } from "react-hook-form"
import { LoginSchema, LoginSchemaType } from "app/auth/validations"
import { showErrorModal } from "@Utils/errors"
import SSOButtons from "./SSOButtons"
import AuthFormCard from "./AuthFormCard"
import login from "app/auth/mutations/login"
import useEffectOnce from "@Hooks/useEffectOnce"
import Link from "@Components/ui/Link"
import Button from "@Components/ui/Button"
import Form, { FormItem, FormLabel, FormMessage } from "@Components/ui/Form"
import Input from "@Components/ui/Input"
import { IconArrowLeft, IconKey, IconUser } from "@tabler/icons-react"

interface LoginFormProps {
  onSuccess?: () => void
}

const defaultValues: LoginSchemaType = {
  email: "",
  password: "",
}

export const LoginForm: React.FC<LoginFormProps> = ({ onSuccess }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [showEmailForm, setShowEmailForm] = useState<boolean>(false)
  const [loginMutation] = useMutation(login)
  const router = useRouter()
  const queryParams = useRouter().query

  const form = useForm<LoginSchemaType>({
    resolver: zodResolver(LoginSchema),
    mode: "onChange",
    defaultValues,
  })

  const {
    register,
    formState: { errors, isValid },
    getValues,
    handleSubmit,
  } = form

  const email = form.watch("email")

  const onSubmit = useCallback(async () => {
    setIsLoading(true)
    const values = getValues()
    try {
      const user = await loginMutation(values)

      if (!user.emailConfirmed) {
        await router.push(`/email-confirmation/${user.email}`)
        return
      }

      onSuccess?.()
      await router.push(
        user?.customer?.status === CustomerStatus.PROFILE_SETUP ? "/profile-setup" : "/main"
      )
      if (!window.frameElement) {
        message.success("Logged in successfully")
      }
    } catch (error: any) {
      console.log("Log in error", error)
      showErrorModal(error.message)
    } finally {
      setIsLoading(false)
    }
  }, [getValues, loginMutation, onSuccess, router])

  const handleSignInWithEmail = useCallback(() => {
    setShowEmailForm((prev) => !prev)
  }, [setShowEmailForm])

  const forgotPasswordLink = email ? `/forgot-password?email=${email}` : "/forgot-password"

  useEffectOnce(() => {
    if ("account-deletion" in queryParams) {
      Modal.info({
        title: "Your BrokerBlocks account is scheduled for deletion",
        content: (
          <div>
            <p>
              You have been logged out and your account has been scheduled for deletion in 10 days.
            </p>
            <p>Deleting of the account after 10 days is irreversible.</p>
            <p>
              If you log in during those 10 days, then the scheduled deletion will get cancelled and
              you will get an e-mail about this fact.
            </p>
          </div>
        ),
        okButtonProps: {
          className: "bg-blue-600",
        },
        onOk: () => {
          router.push("/login")
        },
      })
    }
  })

  return (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit(onSubmit)} className="form">
        <AuthFormCard>
          <h3 className="text-blue-100 text-center font-bold mb-[3.2rem]">
            {showEmailForm ? "Sign in" : "Welcome"}
          </h3>
          {!showEmailForm ? (
            <div key="sso-buttons" className="opacity-0 animate-smoothFadeInUp">
              <SSOButtons onContinueWithEmail={handleSignInWithEmail} />
            </div>
          ) : (
            <div key="signin-form" className="opacity-0 animate-smoothFadeInUp">
              <Button
                data-testid="signin-back-button"
                transparent
                square
                icon={<IconArrowLeft size={16} />}
                onClick={handleSignInWithEmail}
                className="mb-[2rem] !px-0 !py-0"
              >
                Back
              </Button>
              <Form>
                <FormItem>
                  <FormLabel htmlFor="input-email">Email</FormLabel>
                  <Input
                    {...register("email")}
                    id="input-email"
                    data-testid="signin-email-input"
                    placeholder="Enter your email"
                    prefix={<IconUser size={16} />}
                  />
                  {errors.email && <FormMessage error>{errors.email.message}</FormMessage>}
                </FormItem>
                <FormItem>
                  <FormLabel htmlFor="input-password">Password</FormLabel>
                  <Input
                    {...register("password")}
                    id="input-password"
                    data-testid="signin-password-input"
                    placeholder="Enter your password"
                    prefix={<IconKey size={16} />}
                    type="password"
                    togglePassword
                  />
                  {errors.password && <FormMessage error>{errors.password.message}</FormMessage>}
                </FormItem>
                <div>
                  <Link
                    data-testid="forgot-password-link"
                    href={forgotPasswordLink}
                    className="mr-[0.2rem] mb-[1.2rem] text-[1.4rem]"
                  >
                    Forgot your password?
                  </Link>
                </div>
                <FormItem>
                  <Button
                    data-testid="signin-button"
                    type="submit"
                    disabled={!isValid}
                    loading={isLoading}
                  >
                    Sign in
                  </Button>
                </FormItem>
              </Form>
            </div>
          )}
          {!showEmailForm && (
            <div className="mt-[2rem] text-center text-[1.5rem]">
              {"You don't have an account? "}
              <Link data-testid="signup-link" href="/signup">
                Create an account
              </Link>
            </div>
          )}
        </AuthFormCard>
      </form>
    </FormProvider>
  )
}

export default LoginForm
